<template>
  <div class="d-flex doc-name-wrapper">
    <div
      v-if="isApprovalProcess && isInternEditor"
      class="doc-name-flex-checkbox"
    >
      <FreigabeCheckbox
        v-if="currentDocId"
        :keys="checkboxKeys"
        :revision="elementRevision"
        :element-id="currentDocId"
      />
    </div>
    <div class="doc-name-flex-content">
      <bbh-input
        id="current-doc-name"
        :value.sync="preparedValue"
        :custom-class="customClass"
        :single-line="true"
        height="32px"
      >
        <template #append-inner>
          <template v-if="!nameChanged">
            <v-icon>
              $pencil
            </v-icon>
          </template>
          <template v-else>
            <div class="append-icon-wrapper">
              <v-icon @click="resetDocName()">
                $removeCircle
              </v-icon>
              <v-icon @click="saveDocName()">
                $positiveTick
              </v-icon>
            </div>
          </template>
        </template>
      </bbh-input>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';
import { isEmpty } from '@st/utils-js';
import FreigabeCheckbox from '../freigabe/FreigabeCheckbox.vue';
import types from '../../store/types';

import FreigabeStatus from '../../model/enums/FreigabeStatus';
import ElementTyp from '../../model/enums/ElementTyp';

const editorMutations = types.mutations.editor;

const sharedEditorGetters = types.getters.sharedEditor;
const sharedEditorActions = types.actions.sharedEditor;

const mandantEditorMutations = types.mutations.mandantEditor;
const mandantEditorGetters = types.getters.mandantEditor;

export default {
  name: 'DocName',
  components: { FreigabeCheckbox },
  data: () => ({
    preparedValue: ''
  }),
  computed: {
    ...mapGetters({
      currentClientElementName: mandantEditorGetters.CURRENT_CLIENT_ELEMENT_NAME,
      currentClientElementId: mandantEditorGetters.CURRENT_CLIENT_ELEMENT_ID,

      freigabe: sharedEditorGetters.FREIGABE_STATUS
    }),

    ...mapState({
      currentDocId: (state) => state.editor.currentDocId,
      element: (state) => state.editor.element
    }),

    currentDocElement() {
      return this.element?.elements?.[this.currentDocId] || {};
    },

    elementRevision() {
      if (isEmpty(this.element)) return 0;
      return this.element.elementRevisions[this.currentDocId];
    },

    isApprovalProcess() {
      if (this.isMandantEditor) {
        return FreigabeStatus.isApprovalRequested(this.freigabe.status) || false;
      }
      if (this.isInternEditor) {
        return this.freigabe.isApproval || false;
      }
      return false;
    },

    isInternEditor() {
      return this.indicator === 'Intern';
    },

    isMandantEditor() {
      return this.indicator === 'Mandant';
    },

    checkboxKeys() {
      const checkboxKey = 'name';
      const editorElement = this.element.elements[this.element.editorElement];
      const elementType = editorElement.type;
      if (ElementTyp.isMustervertrag(elementType)) {
        const elementRefPathId = editorElement.content
          .find((subelement) => subelement.id === this.currentDocId)
          ?.refPathId || '';
        return [
          elementRefPathId, checkboxKey
        ];
      }
      return [ checkboxKey ];
    },

    refPathId() {
      const editorElementId = this.element.editorElement;
      if (!ElementTyp.isMustervertrag(this.element.elements[editorElementId].type)) return null;

      const subelement = this.element.elements[editorElementId]
        .content
        .find((s) => s.id === this.currentDocId);

      return subelement.refPathId;
    },

    localDocName() {
      if (this.isMandantEditor) {
        return this.currentClientElementName;
      }
      return this.currentDocElement?.attrs?.name || '';
    },

    nameChanged() {
      return this.preparedValue !== this.localDocName;
    },

    indicator() {
      return this.$route.name === 'Editor' ? 'Mandant' : 'Intern';
    },

    customClass() {
      const staticName = 'doc-name-input-field';
      const dynamicName = this.nameChanged ? 'changed' : '';
      return `${staticName} ${dynamicName}`;
    }
  },
  watch: {
    localDocName: {
      immediate: true,
      handler: 'initData'
    }
  },
  methods: {
    ...mapActions({
      toggleCheckboxProgress: sharedEditorActions.TOGGLE_CHECKBOX_TASKPROGRESS
    }),
    ...mapMutations({
      updateDocName: editorMutations.UPDATE_DOC_NAME,
      updateClientElementDocName: mandantEditorMutations.UPDATE_CLIENT_ELEMENT_DOC_NAME
    }),

    updateTaskprogressCheckbox() {
      if (this.isApprovalProcess && this.checkboxKeys) {
        this.toggleCheckboxProgress({
          elementId: this.currentDocId,
          exceptKey: this.checkboxKeys.join('_')
        });
      }
    },

    initData() {
      this.preparedValue = this.localDocName;
    },

    resetDocName() {
      this.preparedValue = this.localDocName;
    },

    saveDocName() {
      this.updateTaskprogressCheckbox();
      if (this.isMandantEditor) {
        return this.updateClientElementDocName({
          dokumentId: this.currentClientElementId,
          currentDocName: this.preparedValue
        });
      }
      return this.updateDocName({
        dokumentId: this.currentDocId,
        currentDocName: this.preparedValue
      });
    }
  }
};
</script>

<style lang='scss'>
.doc-name-wrapper {
  align-items: center;

  & > .doc-name-flex-checkbox {
    flex-shrink: 1;
    padding-right: 15px;
  }

  & > .doc-name-flex-content {
    flex-grow: 1;
  }
}

.doc-name-input-field.v-input.bbh-custom-input.v-text-field {
  position: relative;
  left: -20px;

  background: transparent;

  width: calc(100% * 2 / 3);

  --bbh-text-field-color: var(--bbh-white);

  & .v-text-field__slot input {
    color: var(--bbh-white);
  }

  & .v-text-field__slot {
    height: 41px;
    width: max-content;
  }

  & .append-icon-wrapper {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 5px;

    & .v-icon .v-icon__component {
      width: 20px;
      height: 20px;

      cursor: pointer;

      opacity: 0.4;

      --bbh-icon-color: var(--bbh-white);
      --bbh-icon-color-check: var(--bbh-white);
      --bbh-icon-background-color: transparent;

      &:hover {
        opacity: 1;
      }
    }
  }

  & .v-icon {
    display: none;

    & .v-icon__component {
      width: 18px;
      height: 18px;

      --bbh-icon-color: var(--bbh-white);
    }
  }

  & .v-input__append-inner {
    padding-left: 15px;

    & .v-input__icon.v-input__icon--append {
      margin: 4px 0;
      height: 100%;
    }
  }

  &.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 41px;
  }

  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    > fieldset {
    border-color: transparent;
  }

  &.v-text-field--outlined.v-input--is-focused {
    & .v-icon {
      display: block;
    }
    & fieldset {
      opacity: 0.4;
      top: -14px;
    }
    &.changed fieldset {
      opacity: 1;
    }
  }

  &.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 15px 0 20px;
  }
}
</style>
