import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_CLIENT_ELEMENT: `${MODULE}set_client_element`,
  SET_CLIENT_ELEMENTS: `${MODULE}set_client_elements`,
  SET_CLIENT_ELEMENT_NAME: `${MODULE}set_client_element_name`,
  SET_CLIENT_ELEMENT_NOTES: `${MODULE}set_client_element_notes`,
  SET_CURRENT_CLIENT_ELEMENT_ID: `${MODULE}set_current_client_element_id`,
  SET_REVISIONS_ARRAY: `${MODULE}set_revisions_array`,

  SET_CLIENT_ELEMENT_RELEASE_FLAG: `${MODULE}set_client_element_release_flag`,

  SET_CLIENT_ELEMENT_TAGS: `${MODULE}set_client_element_tags`,

  // Update Prozess
  SET_MANDANT_COMPARE_ELEMENT: `${MODULE}set_mandant_compare_element`,
  OVERWRITE_SECTION_WITH_UPDATE: `${MODULE}overwrite_section_with_update`,
  SET_CLIENT_ELEMENT_ZWANGSUPDATE: `${MODULE}set_client_element_zwangsupdate`,
  REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY: `${MODULE}remove_client_element_zwangsupdate_key`,
  SET_UPDATE_COMPLETED: `${MODULE}set_update_completed`,
  INCREASE_UPDATE_IS_FINISHED_COUNTER: `${MODULE}set_check_if_update_is_finished_counter`,
  REMOVE_UPDATE_FROM_CLIENT_ELEMENT: `${MODULE}remove_update_from_client_element`,

  SET_CLIENT_ELEMENT_UPDATES: `${MODULE}set_client_element_updates`,

  UPDATE_MANDANT_ORDER: `${MODULE}update_mandant_order`,
  UPDATE_MANDANT_SECTION: `${MODULE}update_mandant_section`,
  UPDATE_SECTION_ATTRS: `${MODULE}update_section_attrs`,
  UPDATE_MANDANT_DOKUMENT: `${MODULE}update_mandant_dokument`,
  UPDATE_MANDANT_REVISION: `${MODULE}update_mandant_revision`,

  UPDATE_CLIENT_ELEMENT_DOC_NAME: `${MODULE}update_client_element_doc_name`,

  UPDATE_LEVEL_IN_CLIENT_ELEMENT: `${MODULE}update_level_in_client_element`,

  DEPRECATE_CLIENT_ELEMENT: `${MODULE}deprecate_client_element`,

  REMOVE_SUBELEMENT_FROM_CLIENT_ELEMENT_LIST: `${MODULE}remove_subelement_from_client_element_list`,
  REMOVE_SECTION_FROM_SUBELEMENT: `${MODULE}remove_section_from_subelement`,

  ADD_CLIENT_ELEMENT_TO_CLIENT_ELEMENT_LIST: `${MODULE}add_client_element_to_client_element_list`,
  ADD_SUBELEMENT_TO_CLIENT_ELEMENT_LIST: `${MODULE}add_subelement_to_client_element_list`,
  ADD_NACHFOLGER_TO_CLIENT_ELEMENT: `${MODULE}add_nachfolger_to_client_element`,
  ADD_CLAUSE_TO_CURRENT_MANDANT_DOCUMENT: `${MODULE}add_clause_to_current_mandant_document`,

  PUT_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}put_client_element_responsibility`,
  DELETE_TAG_FROM_ALL_CLIENT_ELEMENTS: `${MODULE}delete_tag_from_all_client_elements`,
  DELETE_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}delete_client_element_responsibility`,

  UPDATE_CLIENT_ELEMENT_REVISIONS_ARRAY: `${MODULE}update_client_element_revisions_array`,
  UPDATE_TAG_IN_CLIENT_ELEMENTS: `${MODULE}update_tag_in_client_elements`
};

export default MUTATIONS;
