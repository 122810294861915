<template>
  <div v-if="showServicezugang">
    <bbh-select
      label="Servicezugang"
      dense
      hide-details
      no-icon-text
      :items="userClients"
      :value="currentClient"
      item-text="name"
      return-object
      @update="handleChangeClient"
    />
  </div>
</template>

<script>
import services from '@src/services';
import types from '@src/store/types';
import { mapGetters, mapMutations } from 'vuex';

const userGetters = types.getters.user;
const userMutations = types.mutations.user;

const viewContentMutations = types.mutations.viewContent;

export default {
  name: 'ServicezugangAuswahl',
  computed: {
    ...mapGetters({
      userClients: userGetters.USER_CLIENTS
    }),

    showServicezugang() {
      return this.userClients.length > 1;
    },

    currentClient() {
      return this.userClients[0];
    }
  },
  methods: {
    ...mapMutations({
      setClientToPositionZero: userMutations.SET_CLIENT_TO_POSITION_ZERO,

      showSnackbarError: viewContentMutations.SHOW_SNACKBAR_ERROR
    }),

    async handleChangeClient(client) {
      try {
        await services.editor.client.putActiveClient(client.clientId);
        this.setClientToPositionZero({ clientId: client.clientId });
      } catch (error) {
        if (error.response?.status === 403) {
          this.showSnackbarError('Sie haben keine Berechtigung, diese Aufgabe zu bearbeiten');
          return;
        }
        this.showSnackbarError('Fehler beim Wechseln des Servicezugangs');
      }
    }
  }
};
</script>

<style lang='scss' scoped>
</style>

<style lang='scss'>
</style>
