import {
  get, post, put, remove
} from '../axios';

const API_PREFIX = '/shopAdmin';

export default {
  /**
   * Get all packages
   */
  getPakete() {
    const URL = `${API_PREFIX}/pakete`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get all vertragsgenerator-pakete
   * @returns
   */
  getVertragsgeneratorPakete() {
    const URL = `${API_PREFIX}/vertragsgenerator/pakete`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get Mandanten mit aufgaben
   */
  fetchMandanten() {
    const URL = `${API_PREFIX}/mandant/liste`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get all clients
   */
  fetchClients() {
    const URL = `${API_PREFIX}/client`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  fetchMandantPakete(clientId) {
    const URL = `${API_PREFIX}/mandant/${clientId}/vertragsgenerator/pakete`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  updateMandantPakete(clientId, body) {
    const URL = `${API_PREFIX}/mandant/${clientId}/vertragsgenerator/pakete`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * post vertragsgenerator pakete des aufgabenBearbeiters
   *
   * @param {string} aufgabeBearbeiterId
   * @param {string} body mit Pakete als Array mit paketIds
   */
  postVertragsgeneratorPakete(aufgabeBearbeiterId, body) {
    const URL = `${API_PREFIX}/vertragsgenerator/pakete/${aufgabeBearbeiterId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get MandantenDaten
   *
   * @param {string} aufgabenBearbeiterId
   */
  getMandantenDaten(aufgabenBearbeiterId) {
    const URL = `${API_PREFIX}/mandant/${aufgabenBearbeiterId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get MandantenDaten
   *
   * @param {string} clientId
   */
  fetchUnternehmensDaten(clientId) {
    const URL = `${API_PREFIX}/mandant/${clientId}/unternehmensdaten`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get sparten
   */
  getSparten() {
    const URL = `${API_PREFIX}/sparten`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Create new package
   *
   * @param {Object} body
   */
  postPaket(body) {
    const URL = `${API_PREFIX}/pakete/create`;

    return new Promise((resolve, reject) => {
      post(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Update package
   *
   * @param {Object} body
   * @param {String} id
   */
  putPaket(body, id) {
    const URL = `${API_PREFIX}/pakete/${id}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Delete package
   *
   * @param {String} id
   */
  deletePaket(id) {
    const URL = `${API_PREFIX}/pakete/${id}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Get all not assigned packages
   *
   * @param {String} paketId
   */
  getPaketeNotAssigned(paketId) {
    const URL = `${API_PREFIX}/pakete/${paketId}/notAssigned`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Get all not assigned contracts
   */
  getVertraegeNotAssigned() {
    const URL = `${API_PREFIX}/vertraege/notAssigned`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Add package to package
   *
   * @param {String} wrappedPaketId
   * @param {String} addedPaketId
   */
  addPaketToPaket(wrappedPaketId, addedPaketId) {
    const URL = `${API_PREFIX}/pakete/${wrappedPaketId}/addPaket/${addedPaketId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Add contract to package
   *
   * @param {String} wrappedPaketId
   * @param {String} addedVertragId
   */
  addVertragToPaket(wrappedPaketId, addedVertragId) {
    const URL = `${API_PREFIX}/pakete/${wrappedPaketId}/addVertrag/${addedVertragId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * set sparte of package
   *
   * @param {string} paketId
   * @param {string} sparteId
   */
  setSparte(paketId, sparteId) {
    const URL = `${API_PREFIX}/setSparte/${paketId}/${sparteId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * get clientVertragAboPreis
   *
   * @param {string} clientId
   * @param {string} paketId
   * @param {string} vertragsId
   */
  fetchClientVertragAboPreis(clientId, paketId, vertragsId) {
    const URL = `${API_PREFIX}/clientVertragAboPreis/${clientId}/${paketId}/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put clientVertragAboPreis
   *
   * @param {string} clientId
   * @param {string} paketId
   * @param {string} vertragsId
   * @param {object} body // { "aboPreis": <number> }
   */
  setOrUpdateClientVertragAboPreis(clientId, paketId, vertragsId, body) {
    const URL = `${API_PREFIX}/clientVertragAboPreis/${clientId}/${paketId}/${vertragsId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get clientPaketInitialPreis
   *
   * @param {string} clientId
   * @param {string} paketId
   */
  fetchClientPaketInitialPreis(clientId, paketId) {
    const URL = `${API_PREFIX}/clientPaketInitialPreis/${clientId}/${paketId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put clientPaketInitialPreis
   *
   * @param {string} clientId
   * @param {string} paketId
   * @param {object} body // { "initialPreis": <number> }
   */
  setOrUpdateClientPaketInitialPreis(clientId, paketId, body) {
    const URL = `${API_PREFIX}/clientPaketInitialPreis/${clientId}/${paketId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * Receive packages that include a contract
   *
   * @param {string} vertragsId
   */
  fetchPaketeByVertrag(vertragsId) {
    const URL = `${API_PREFIX}/paketeByVertrag/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get clientPaketAboPreis
   *
   * @param {string} paketId
   * @param {string} vertragsId
   */
  fetchPaketVertragAboPreis(paketId, vertragsId) {
    const URL = `${API_PREFIX}/paketVertragAboPreis/${paketId}/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put clientPaketAboPreis
   *
   * @param {string} paketId
   * @param {string} vertragsId
   * @param {object} body // { kleinst: ..., klein: ..., mittel: ..., groß: ..., sehrGroß: ... }
   */
  putPaketVertragAboPreis(paketId, vertragsId, body) {
    const URL = `${API_PREFIX}/paketVertragAboPreis/${paketId}/${vertragsId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get VertragsDetails by vertragsId
   *
   * @param {string} vertragsId
   */
  fetchVertragsDetails(vertragsId) {
    const URL = `${API_PREFIX}/vertragDetailsByVertragId/${vertragsId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get Rechungen
   *
   * @param {string} zeitraum // YYYYY-MM
   * @param {string} versendet // versendet/offen
   */
  fetchRechnungen(zeitraum, versendet) {
    const URL = `${API_PREFIX}/rechnungen/${zeitraum}/${versendet}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put Rechnung-Notiz
   *
   * @param {string} clientId
   * @param {object} body
   */
  putRechnungNotiz(clientId, body) {
    const URL = `${API_PREFIX}/rechnung/notizen/${clientId}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * send Rechnung
   *
   * @param {string} clientId
   * @param {string} zeitraum // YYYYY-MM
   */
  sendRechnung(clientId, zeitraum) {
    const URL = `${API_PREFIX}/rechnung/${clientId}/${zeitraum}/senden`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get RechnungsPositionen
   *
   * @param {string} clientId
   * @param {string} zeitraum // YYYYY-MM
   */
  fetchRechnungsPositionen(clientId, zeitraum) {
    const URL = `${API_PREFIX}/rechnungsPositionen/${clientId}/${zeitraum}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * put RechnungsPositionen
   *
   * @param {string} clientId
   * @param {string} zeitraum // YYYYY-MM
   * @param {object} body // RechnungsPosition
   */
  putRechnungsPositionen(clientId, zeitraum, body) {
    const URL = `${API_PREFIX}/rechnungsPosition/${clientId}/${zeitraum}`;

    return new Promise((resolve, reject) => {
      put(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * delete RechnungsPositionen
   *
   * @param {string} rechnungsPositionId
   */
  deleteRechnungsPosition(rechnungsPositionId) {
    const URL = `${API_PREFIX}/rechnungsPosition/${rechnungsPositionId}`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * get all Kostenstellen
   *
   * @returns
   */
  getKostenstellen() {
    const URL = `${API_PREFIX}/kostenstellen`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
};
