import { component as MODULE } from '../store/state';

const GETTERS = {
  CLIENT_ELEMENT: `${MODULE}client_element`,
  ALL_CLIENT_ELEMENTS: `${MODULE}all_client_elements`,
  CURRENT_CLIENT_ELEMENT_ID: `${MODULE}current_client_element_id`,
  CURRENT_CLIENT_ELEMENT: `${MODULE}current_client_element`,
  CURRENT_CLIENT_ELEMENT_NAME: `${MODULE}current_client_element_name`,

  MANDANT_COMPARE_ELEMENT: `${MODULE}mandant_compare_element`
};

export default GETTERS;
