export default {
  clientElement: {},
  clientElements: [],
  currentClientElementId: '',

  mandantCompareElement: {},

  clientElementZwangsupdate: {},
  updateIsFinishedCounter: 0,
  revisionsArray: []
};

export const component = 'mandantEditor/';
